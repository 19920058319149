/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
/* prettier-ignore-start */
import * as React from "react";

export const _040420231000042820231000Context = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function use_040420231000042820231000() {
  return React.useContext(_040420231000042820231000Context);
}

export default _040420231000042820231000Context;
/* prettier-ignore-end */
